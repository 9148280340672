export const _storeList = [
  {storeName:'문막토방',
    storeIp:'211.108.244.155',
    registDate: '2024-01-02',
    machineQuantity: 3
  },{storeName:'산본 골드',
    storeIp:'119.207.187.192',
    registDate: '2024-03-05',
    machineQuantity: 5
  },{storeName:'원주우리기프트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-25',
    machineQuantity: 1
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  },{storeName:'네버리스트',
    storeIp:'211.216.38.239',
    registDate: '2023-09-20',
    machineQuantity: 4
  }
]

export const _deviceList = [
  {deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '메모임1111111111',
  },{deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '',
  },{deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '',
  },{deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '메모22',
  },{deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '',
  },{deviceCode:'EB010324',
    deviceNumber:'5',
    registDate: '2024-01-02',
    totalPrint: 3571,
    todayPrint: 123,
    yesterdayPrint: 456,
    ereyesterdayPrint: 789,
    memo: '',
  },
]

export const _happyPickGameList = [
  { gameRound: 111,
    minRate: 67.84,
    maxRate: 152.84,
    gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', gameDate:'2024-01-02'},
      {gameNumber: '056', homeTeam:'OK저축', awayTeam: '대한항공', score:'0:3', gameDate:'2024-01-02'},
      {gameNumber: '102', homeTeam:'토론블루', awayTeam: '보스레드', score:'1:4', gameDate:'2024-01-02'},
      {gameNumber: '123', homeTeam:'애리다이', awayTeam: '샌프자이', score:'3:6', gameDate:'2024-01-02'},
      {gameNumber: '221', homeTeam:'KIA', awayTeam: '삼성', score:'5:3', gameDate:'2024-01-02'},
    ]
  },{ gameRound: 111,
    minRate: 48.84,
    maxRate: 173.84,
    gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', gameDate:'2024-01-02'},
      {gameNumber: '056', homeTeam:'OK저축', awayTeam: '대한항공', score:'0:3', gameDate:'2024-01-02'},
      {gameNumber: '102', homeTeam:'토론블루', awayTeam: '보스레드', score:'1:4', gameDate:'2024-01-02'},
      {gameNumber: '123', homeTeam:'애리다이', awayTeam: '샌프자이', score:'3:6', gameDate:'2024-01-02'},
      {gameNumber: '221', homeTeam:'KIA', awayTeam: '삼성', score:'5:3', gameDate:'2024-01-02'},
    ]
  },{ gameRound: 111,
    minRate: 26.84,
    maxRate: 92.84,
    gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', gameDate:'2024-01-02'},
      {gameNumber: '056', homeTeam:'OK저축', awayTeam: '대한항공', score:'0:3', gameDate:'2024-01-02'},
      {gameNumber: '102', homeTeam:'토론블루', awayTeam: '보스레드', score:'1:4', gameDate:'2024-01-02'},
      {gameNumber: '123', homeTeam:'애리다이', awayTeam: '샌프자이', score:'3:6', gameDate:'2024-01-02'},
      {gameNumber: '221', homeTeam:'KIA', awayTeam: '삼성', score:'5:3', gameDate:'2024-01-02'},
    ]
  },
]

export const _happyPickBattingList = [
  {gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', rate:'2.65', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'0:3', rate:'3.50', batting:'무'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'1:4', rate:'2.06', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:6', rate:'1.84', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'5:3', rate:'1.97', batting:'역'},
    ]
  },{gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', rate:'2.65', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'0:3', rate:'3.50', batting:'무'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'1:4', rate:'2.06', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:6', rate:'1.84', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'5:3', rate:'1.97', batting:'역'},
    ]
  },{gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', rate:'2.65', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'0:3', rate:'3.50', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'1:4', rate:'2.06', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:6', rate:'1.84', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'5:3', rate:'1.97', batting:'역'},
    ]
  },{gameList : [
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:2', rate:'2.65', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'0:3', rate:'3.50', batting:'정'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'1:4', rate:'2.06', batting:'역'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'3:6', rate:'1.84', batting:'승'},
      {gameNumber: '021', homeTeam:'이란', awayTeam: '우즈베키', score:'5:3', rate:'1.97', batting:'무'},
    ]
  },
]