
const DeviceListTable = ({deviceList, setSelectDevice}) => {

  const printCountTop3List = (list) => {
    return Object.entries(list).slice(0, 3); // 상위 3개 항목만 선택
  }

  return (
      <div className="w-full h-[21.5rem] overflow-x-auto overflow-y-auto border border-gray-300">
        <table className="min-w-full text-sm">
          <thead className="bg-violet-900 text-white sticky top-0">
          <tr className="text-center">
            <th className="">코드</th>
            <th className="p-3">번호</th>
            <th className="">등록일</th>
            <th className="p-3">총누적인쇄</th>
            <th className="p-3">오늘</th>
            <th className="p-3">어제</th>
            <th className="p-3">그저께</th>
            <th className="p-3 whitespace-nowrap min-w-10">메모</th>
            <th className="p-3"></th>
          </tr>
          </thead>

          <tbody>
          {deviceList.map((device, index) => {
            return (
                <tr className="border-b border-opacity-60 border-gray-300 bg-white hover:bg-gray-100 text-center" key={index}>
                  {/*기기코드*/}
                  <td className="">
                    <p>{device.machineCodeName}</p>
                  </td>
                  {/*기기번호*/}
                  <td className="p-3">
                    <p>{device.machineNumber}</p>
                  </td>
                  {/*등록일*/}
                  <td className="whitespace-nowrap">
                    <p>{device.machineRegistrationDateTime.split('T')[0]}</p>
                  </td>
                  {/*누적인쇄*/}
                  <td className="p-3">
                    <p>{device.machineTotalPrintNumber}</p>
                  </td>
                  {/*날짜별인쇄*/}
                  {printCountTop3List(device.machinePrintCountNumberMap).map(([date, number]) => (
                      <td className="p-3" key={date}>
                        <p>{number}</p>
                      </td>
                  ))}
                  {/*메모*/}
                  <td className="p-3 min-w-10">
                    <p>{device.machineComment}</p>
                  </td>

                  <td>
                    <button className="button-style px-2 py-1.5 text-sm"
                            onClick={()=>setSelectDevice(device)}
                    >
                      관리
                    </button>
                  </td>
                </tr>
            )
          })}
          </tbody>
        </table>
      </div>
  )
}
export default DeviceListTable;