import React from 'react';


// <-- 알림 모달 핸들링 -->
export const NotifyModalHandler = () => {
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = React.useState(false);

  const [modalMessageTitle, setModalMessageTitle] = React.useState('');
  const [modalMessageContents, setModalMessageContents] = React.useState('');

  const [modalMenuJson, setModalMenuJson] = React.useState({});
  {/*{'text':'보여줄메뉴이름', params : {},*/}
  {/*  'onClick':(arg, text, params) => { arg.isClose=false; }*/}
  {/*}*/}

  // 경고 모달창 띄우기
  const openWarningModal = (title, contents) => {
    setIsWarningModalOpen(true);
    setModalMessageTitle(title);
    setModalMessageContents(contents);
  }
  // 확인 모달창 띄우기
  const openConfirmModal = (title, contents) => {
    setIsConfirmModalOpen(true);
    setModalMessageTitle(title);
    setModalMessageContents(contents);
  }
  // 선택 모달창 띄우기
  const openSelectModal = (title, contents, menuJson) => {
    setIsSelectModalOpen(true);
    setModalMessageTitle(title);
    setModalMessageContents(contents);
    setModalMenuJson(menuJson);
  }

  return [isWarningModalOpen, setIsWarningModalOpen, isConfirmModalOpen, setIsConfirmModalOpen,
    isSelectModalOpen, setIsSelectModalOpen,
    modalMessageTitle, modalMessageContents,modalMenuJson, openWarningModal, openConfirmModal, openSelectModal];
}

