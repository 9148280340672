import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {LOGIN_PAGE, MAIN_TEMP_PAGE} from "./constants/page_constants";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";

function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === LOGIN_PAGE;

  return (
      <>
        <Routes>
          <Route path={LOGIN_PAGE} element={<LoginPage/>}/>

          <Route path="/*" element={<MainPage />} />
          <Route path={MAIN_TEMP_PAGE} element={<MainPage />} />

          {/* "/" 경로로 들어오면 로그인 페이지로 리다이렉트 */}
          <Route path="/" element={<Navigate to={LOGIN_PAGE} />} />
        </Routes>
      </>
  );
}

export default App;
