import {Route, Routes} from "react-router-dom";
import {
  CUSTOM_PICK_MANAGEMENT_PAGE,
  HAPPY_PICK_LIST_PAGE,
  HAPPY_PICK_MANAGEMENT_PAGE,
  LOGIN_PAGE, STORE_MANAGEMENT_PAGE
} from "../constants/page_constants";
import LoginPage from "./LoginPage";
import HappyPickManagementPage from "./HappyPickManagementPage";
import HappyPickOngoingListPage from "./HappyPickOngoingListPage";
import CustomPickManagementPage from "./CustomPickManagementPage";
import StoreManagementPage from "./StoreManagementPage";
import Header from "../components/Header";

const MainPage = () => {
  return(
      <div className="w-full h-full">
        <Header/>

        <div className="h-[calc(100%-4rem)] w-full bg-gray-100 border-t border-gray-300 flex-grow">
          <Routes>
            <Route path={STORE_MANAGEMENT_PAGE} element={<StoreManagementPage/>}/>

            <Route path={HAPPY_PICK_MANAGEMENT_PAGE} element={<HappyPickManagementPage/>}/>
            <Route path={HAPPY_PICK_LIST_PAGE} element={<HappyPickOngoingListPage/>}/>

            <Route path={CUSTOM_PICK_MANAGEMENT_PAGE} element={<CustomPickManagementPage/>}/>
          </Routes>
        </div>
      </div>
  )
}
export default MainPage;