export const CREATE_ACCOUNT_API = '/auth/account/set/auth_account' // 회원가입
export const LOGIN_API = '/auth/login/set/auth_login' // 로그인

// <-- 지점 관리 -->
export const GET_STORE_LIST_API = '/management/store/list/get/management_store_list' // 등록된 지점 리스트 조회
export const ADD_STORE_API = '/management/store/set/management_store' // 새 지점 등록
export const EDIT_STORE_INFO_API = '/management/store/edit/management_store' // 지점 정보 수정
export const DELETE_STORE_API = '/management/store/delete/management_machine' // 지점 삭제
export const GET_TOTAL_COUNT_REPORT_API = '/management/total/report/get/management_total_report' // 총 프로토 지점 수 + 어제 오늘 출력 수
export const GET_DEVICE_LIST_API = '/management/machine/list/get/filter/store/management_machine_list' // 등록된 기기 리스트 조회
export const ADD_DEVICE_API = '/management/machine/set/management_machine' // 새 기기 추가
export const EDIT_DEVICE_INFO_API = '/management/machine/edit/management_machine' // 기기 정보 수정
export const DELETE_DEVICE_API = '/management/machine/delete/management_machine'

// <-- 행복픽 ////---보류---//// -->
export const GET_HAPPY_PICK_LIST_API = '/getHappyPickList' // 행복픽 리스트 불러오기 (전자게시판 동일) FIXME
export const EDIT_HAPPY_PICK_API = '/editHappyPick' // 행복픽 리스트 불러오기 FIXME
export const DELETE_HAPPY_PICK_API = '/deleteHappyPick' // 행복픽 리스트 불러오기 FIXME

// <-- 커스텀픽 ////---보류---//// -->
export const GET_SAME_RATE_GAME_LIST_API = '/getSameRateGameList' // 커스텀픽 조합을 위한 경기 리스트 불러오기 (전자게시판 동일) FIXME
export const SET_HAPPY_PICK_API = '/setHappyPick' // 커스텀픽 조합을 위한 경기 리스트 불러오기 (전자게시판 동일) FIXME
