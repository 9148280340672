
const StoreListTable = ({setManagementAreaState, setSelectStore, storeList}) => {
  const handleSelectStore = (state, store) => {
    setManagementAreaState(state);
    setSelectStore(store);
  }

  return (
      <div className="w-full min-h-[calc(100%-3rem)] container p-4 mx-auto text-gray-800">
        <div
            className="w-full h-[calc(100%-2rem)] overflow-x-auto overflow-y-auto border border-gray-300 bg-white">
          <table className="min-w-full text-sm">
            <thead className="bg-violet-900 text-white sticky top-0">
            <tr className="text-center">
              <th className="pl-5 text-left">지점명</th>
              <th className="p-3">기기수</th>
              <th className="p-3">등록일</th>
              <th></th>
              <th></th>
            </tr>
            </thead>

            <tbody>
            {storeList?.map((store, index) => {
              return (
                  <tr className="border-b border-opacity-60 border-gray-300 bg-white hover:bg-indigo-100 text-center"
                      key={index}>
                    <td className="pl-5 text-left w-48">
                      <p className="font-bold">{store.storeName}</p>
                      {/* TODO : ip 정보 받아올건지? 받아오면 어떻게 받아올거임? */}
                      {/*<p className="text-gray-400">{store.storeIp}</p>*/}
                    </td>
                    <td className="p-3">
                      <p>{store.storeMachineNumber}</p>
                    </td>
                    <td className="p-3">
                      <p>{store.storeRegistrationDateTime.split('T')[0]}</p>
                    </td>
                    <td className="text-right">
                      <button className="button-style bg-gray-400 hover:bg-gray-500 px-3 py-1.5 text-sm"
                              onClick={() => handleSelectStore("deviceManagement", store)}
                      >
                        기기 현황
                      </button>
                    </td>
                    <td>
                      <button className="button-style bg-gray-400 hover:bg-gray-500 px-3 py-1.5 text-sm"
                              onClick={() => handleSelectStore("storeManagement", store)}
                      >
                        지점 관리
                      </button>
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
  )
}
export default StoreListTable;