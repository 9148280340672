import {_happyPickGameList} from "../../_dummyData";
import {RiListCheck} from "react-icons/ri";

const HappyPickGameListTable = () => {
  return (
      <div className="w-full h-full overflow-x-auto overflow-y-auto border border-gray-300 bg-white">
        <table className="min-w-full">
          <thead className="bg-blue-900 text-white sticky top-0">
          <tr className="text-center">
            <th className="p-3">경기일</th>
            <th className="p-3">경기번호</th>
            <th className="p-3">홈팀</th>
            <th className="p-3">스코어</th>
            <th className="p-3">원정팀</th>
          </tr>
          </thead>

          <tbody>
          {_happyPickGameList.map((data, index) => (
              <>
                {/* 회차, 배당 정보 */}
                <tr key={`round-${index}`} className="bg-blue-200 text-center font-bold">
                  <td colSpan={5}>
                    <div className="flex justify-between items-center py-1.5 px-2">
                      <div className="w-32">
                        <p className="inline-block px-3 py-0.5 text-xl font-bold bg-white rounded-lg text-center">{data.gameRound} 회차</p>
                      </div>

                      <div className="w-3/5 flex justify-between pr-5">
                        <div className="flex items-center">
                          <p>예상 최저 배당 : </p>
                          <p className="ml-3 px-3 py-1 bg-blue-800 rounded-full text-white">{data.minRate}</p>
                        </div>
                        <div className="flex items-center">
                          <p>예상 최대 배당 : </p>
                          <p className="ml-3 px-3 py-1 bg-blue-800 rounded-full text-white">{data.maxRate}</p>
                        </div>
                      </div>

                      <button
                          className="button-style bg-blue-700 hover:bg-blue-600 items-center flex flex-row px-5 py-2">
                        <RiListCheck className="w-5 h-auto font-bold mr-2"/>
                        배팅 목록
                      </button>
                    </div>
                  </td>
                </tr>

                {/* 경기 리스트 */}
                {data.gameList.map((game, gameIndex) => (
                    <tr key={gameIndex}
                        className="border-b border-opacity-60 border-gray-300 hover:bg-gray-100 text-center">
                      <td className="p-3">
                        <p>{game.gameDate}</p>
                      </td>
                      <td className="p-3">
                        <p>{game.gameNumber}</p>
                      </td>
                      <td className="p-3">
                        <p>{game.homeTeam}</p>
                      </td>
                      <td className="p-3 tracking-widest">
                        <p>{game.score}</p>
                      </td>
                      <td className="p-3">
                        <p>{game.awayTeam}</p>
                      </td>
                    </tr>
                ))}
              </>
          ))}
          </tbody>
        </table>
      </div>
  )
}
export default HappyPickGameListTable;