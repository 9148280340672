import {Fragment, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {RiCheckLine, RiExpandUpDownLine} from "react-icons/ri";
import clsx from "clsx";

const ListSelectBoxWithDefaultData = ({defaultData, listData, onChange}) => {
  const [selected, setSelected] = useState(defaultData)

  const handleOnChange = (selectOption) => {
    onChange(selectOption);
    setSelected(selectOption);
  }

  if (selected !== defaultData) {
    setSelected(defaultData);
  }

  return (
      <div className="relative mr-3 whitespace-nowrap xl:mt-0">
        <Listbox value={selected} onChange={handleOnChange}>
          {({open}) => (
              <>
                <Listbox.Button
                    className={clsx(
                        'relative min-w-32 max-w-52 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300',
                        'focus:outline-none focus:ring-2 focus:ring-violet-600 sm:text-sm sm:leading-6'
                    )}
                >
                  <span className="block">{selected?.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <RiExpandUpDownLine className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                  </span>
                </Listbox.Button>

                <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                  <Listbox.Options
                      className={'absolute z-10 mt-1 max-h-60 min-w-32 max-w-52 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'}
                  >
                    {listData.map((list) => (
                        <Listbox.Option key={list.id} value={list}
                                        className={({active}) => clsx(active ? 'bg-violet-600 text-white' : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                        )}
                        >
                          {({selected, active}) => (
                              <>
                                <span className={clsx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {list.name}
                                </span>
                                {selected && (
                                    <span className={clsx(active ? 'text-white' : 'text-violet-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-3'
                                    )}>
                                      <RiCheckLine className="h-5 w-5" aria-hidden="true"/>
                                    </span>
                                )}
                              </>
                          )}
                        </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
          )}
        </Listbox>
      </div>
  );
}
export default ListSelectBoxWithDefaultData;