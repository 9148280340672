import {HEADER_MENU} from "../constants/component_constants";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import clsx from "clsx";
import headerIcon from '../assets/headerIcon.png';
import icon from '../assets/icon.png';
import neverLandUtils from "../utils/NeverLandUtils";
import {accountInfo} from "../utils/constants";
import {LOGIN_PAGE} from "../constants/page_constants";

const Header = () => {
  // const [dropDown, setDropDown] = useState({});
  const navigate = useNavigate();

  // const toggleDropdown = (menuTitle) => {
  //   setDropDown((prevState) => ({
  //     ...prevState,
  //     [menuTitle]: !prevState[menuTitle],
  //   }));
  // };

  const logOut = () => {
    neverLandUtils.commons.clear();
    accountInfo.saveLastNavigation(null);
    navigate(LOGIN_PAGE);
  }

  return (
      <div className="h-[4rem] w-full">
        <nav className="w-full bg-white hidden xl:block">
          <div className="container px-6 h-16 flex justify-between items-center lg:items-stretch mx-auto">
            <div className="flex items-center">
              <div className="mr-10 flex items-center">
                <img src={icon} alt="logo" className="h-14 w-auto mr-1"/>
                <h3 className="text-xl text-black font-bold tracking-normal leading-tight hidden lg:block">
                  전자 게시판
                </h3>
              </div>

              <div className="hidden xl:flex items-center h-full">
                {HEADER_MENU.map((menu, index) => {
                  if (menu.menuTitle) {
                    // 드롭다운 메뉴
                    return (
                        <div key={index} className="relative">
                          <button
                              aria-label="dropdown"
                              className="border-b-2 border-transparent focus:border-blue-600 focus:text-blue-600 cursor-pointer h-[4rem] flex items-center text-md hover:text-blue-600 text-gray-800 mx-10 tracking-normal transition duration-150 ease-in-out"
                              // onClick={() => toggleDropdown(menu.menuTitle)}
                          >
                            <menu.icon className="w-5 h-auto mr-2" />
                            {menu.menuTitle}
                          </button>

                          {/* 드롭다운 리스트 */}
                          {/*<ul className={clsx(dropDown[menu.menuTitle] ? '' : 'hidden', 'p-4 w-36 border-r bg-white absolute rounded left-0 shadow mt-16 top-0')}>*/}
                          {/*  {menu.menuList.map((submenu, subIndex) => (*/}
                          {/*      <li*/}
                          {/*          key={subIndex}*/}
                          {/*          onClick={()=> setDropDown({})}*/}
                          {/*          className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-blue-600 focus:text-blue-600 focus:outline-none"*/}
                          {/*      >*/}
                          {/*        <Link*/}
                          {/*            to={submenu.link}*/}
                          {/*            className="focus:outline-none focus:underline focus:text-blue-600 flex items-center"*/}
                          {/*        >*/}
                          {/*          <submenu.icon className="w-4 h-auto" />*/}
                          {/*          <span className="ml-2">{submenu.name}</span>*/}
                          {/*        </Link>*/}
                          {/*      </li>*/}
                          {/*  ))}*/}
                          {/*</ul>*/}
                        </div>
                    );
                  } else {
                    // 일반 메뉴
                    return (
                        <Link
                            key={index}
                            to={menu.link}
                            // onClick={()=> setDropDown({})}
                            className="border-b-2 border-transparent focus:border-indigo-700 focus:text-indigo-700 cursor-pointer h-full flex items-center text-md hover:text-indigo-700 text-gray-800 mx-10 tracking-normal transition duration-150 ease-in-out"
                        >
                          <menu.icon className="w-5 h-auto mr-2" />
                          {menu.name}
                        </Link>
                    );
                  }
                })}
              </div>
            </div>

            <div className="h-full hidden xl:flex items-center justify-end">
              <div className="h-full flex">
                <button
                    className="focus:outline-none text-gray-800 border-b-2 focus:text-indigo-700 border-transparent focus:border-indigo-700 flex items-center pl-8 relative cursor-pointer"
                    onClick={logOut}
                >
                  {/*<img*/}
                  {/*    className="rounded h-10 w-10 object-cover"*/}
                  {/*    src="https://tuk-cdn.s3.amazonaws.com/assets/components/horizontal_navigation/hn_1.png"*/}
                  {/*    alt="logo"*/}
                  {/*/>*/}
                  <p className="text-sm ml-2">로그아웃</p>
                </button>
              </div>
            </div>
          </div>
        </nav>

        {/* <-- 모바일버전 --> */}
        {/*<nav>*/}
        {/*  <div className="py-4 px-6 w-full flex xl:hidden justify-between items-center bg-white fixed top-0 z-40">*/}
        {/*    <div tabIndex="0" className="focus:outline-none w-24">*/}
        {/*      <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg2.svg" alt="logo"/>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <button id="menu" aria-label="open menu"*/}
        {/*              className="focus:outline-none rounded-md focus:ring-2 focus:ring-gray-700 text-gray-800"*/}
        {/*              onClick="sidebarHandler(true)">*/}
        {/*        <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg3.svg" alt="menu"/>*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="absolute w-full h-full transform -translate-x-full z-40 xl:hidden" id="mobile-nav">*/}
        {/*    <div className="bg-gray-800 opacity-50 w-full h-full" onClick="sidebarHandler(false)"></div>*/}
        {/*    <div*/}
        {/*        className="w-64 z-40 fixed overflow-y-auto z-40 top-0 bg-white shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out">*/}
        {/*      <div className="px-6 h-full">*/}
        {/*        <div className="flex flex-col justify-between h-full w-full">*/}
        {/*          <div>*/}
        {/*            <div className="mt-6 flex w-full items-center justify-between">*/}
        {/*              <div className="flex items-center justify-between w-full">*/}
        {/*                <div className="flex items-center">*/}
        {/*                  <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg2.svg"*/}
        {/*                       alt="logo"/>*/}
        {/*                  <p tabIndex="0" className="focus:outline-none text-base md:text-2xl text-gray-800 ml-3">The North</p>*/}
        {/*                </div>*/}
        {/*                <button id="cross" aria-label="close menu"*/}
        {/*                        className="focus:outline-none focus:ring-2 rounded-md text-gray-800"*/}
        {/*                        onClick="sidebarHandler(false)">*/}
        {/*                  <img className="icon icon-tabler icon-tabler-x"*/}
        {/*                       src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg4.svg"*/}
        {/*                       alt="cross"/>*/}
        {/*                </button>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <ul className="f-m-m">*/}
        {/*              <li>*/}
        {/*                <a className="cursor-pointer">*/}
        {/*                  <div className="text-gray-800 pt-8">*/}
        {/*                    <div className="flex items-center">*/}
        {/*                      <div className="w-6 h-6 md:w-8 md:h-8 text-indigo-700">*/}
        {/*                        <img className="icon icon-tabler icon-tabler-grid"*/}
        {/*                             src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg5.svg"*/}
        {/*                             alt="dashboard"/>*/}
        {/*                      </div>*/}
        {/*                      <p tabIndex="0"*/}
        {/*                         className="focus:outline-none focus:text-indigo-600 text-indigo-700 xl:text-base text-base ml-3">Dashboard</p>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </a>*/}
        {/*              </li>*/}
        {/*              <li>*/}
        {/*                <a className="cursor-pointer">*/}
        {/*                  <div className="text-gray-800 pt-8">*/}
        {/*                    <div className="flex items-center justify-between">*/}
        {/*                      <div className="flex items-center">*/}
        {/*                        <div className="w-6 h-6 md:w-8 md:h-8 text-gray-800">*/}
        {/*                          <img className="icon icon-tabler icon-tabler-puzzle"*/}
        {/*                               src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg6.svg"*/}
        {/*                               alt="product"/>*/}
        {/*                        </div>*/}
        {/*                        <p tabIndex="0"*/}
        {/*                           className="focus:outline-none focus:text-indigo-600 text-gray-800 xl:text-base md:text-2xl text-base ml-3">Products</p>*/}
        {/*                      </div>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </a>*/}
        {/*              </li>*/}
        {/*              <li>*/}
        {/*                <a className="cursor-pointer">*/}
        {/*                  <div className="text-gray-800 pt-8">*/}
        {/*                    <div className="flex items-center">*/}
        {/*                      <div className="w-6 h-6 md:w-8 md:h-8 text-gray-800">*/}
        {/*                        <img className="icon icon-tabler icon-tabler-compass"*/}
        {/*                             src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg7.svg"*/}
        {/*                             alt="performance"/>*/}
        {/*                      </div>*/}
        {/*                      <p tabIndex="0"*/}
        {/*                         className="focus:outline-none focus:text-indigo-600 text-gray-800 xl:text-base md:text-2xl text-base ml-3">Performance</p>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </a>*/}
        {/*              </li>*/}
        {/*              <li className="text-gray-800 pt-8 cursor-pointer">*/}
        {/*                <div className="flex items-center justify-between">*/}
        {/*                  <div className="flex items-center">*/}
        {/*                    <div className="w-6 h-6 md:w-8 md:h-8 text-gray-800">*/}
        {/*                      <img className="icon icon-tabler icon-tabler-code"*/}
        {/*                           src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg8.svg"*/}
        {/*                           alt="deliverables"/>*/}
        {/*                    </div>*/}
        {/*                    <p tabIndex="0"*/}
        {/*                       className="focus:outline-none focus:text-indigo-600 text-gray-800 xl:text-base md:text-2xl text-base ml-3">Deliverables</p>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </li>*/}
        {/*            </ul>*/}
        {/*          </div>*/}
        {/*          <div className="w-full pt-4">*/}
        {/*            <div className="flex justify-center mb-4 w-full">*/}
        {/*              <div className="relative w-full">*/}
        {/*                <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">*/}
        {/*                  <img className="icon icon-tabler icon-tabler-search"*/}
        {/*                       src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg9.svg"*/}
        {/*                       alt="search"/>*/}
        {/*                </div>*/}
        {/*                <input*/}
        {/*                    className="focus:ring-2 focus:ring-gray-700 bg-gray-100 focus:outline-none rounded w-full text-sm text-gray-500 pl-10 py-2"*/}
        {/*                    type="text" placeholder="Search"/>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <div className="border-t border-gray-300">*/}
        {/*              <div className="w-full flex items-center justify-between pt-1">*/}
        {/*                <div className="flex items-center">*/}
        {/*                  <img alt="profile-pic"*/}
        {/*                       src="https://tuk-cdn.s3.amazonaws.com/assets/components/boxed_layout/bl_1.png"*/}
        {/*                       tabindex="0"*/}
        {/*                       className="focus:outline-none w-8 h-8 rounded-md"/>*/}
        {/*                  <p className="text-gray-800 text-base leading-4 ml-2">Jane Doe</p>*/}
        {/*                </div>*/}
        {/*                <ul className="flex">*/}
        {/*                  <li className="cursor-pointer text-gray-800 pt-5 pb-3">*/}
        {/*                    <div tabIndex="0"*/}
        {/*                         className="focus:outline-none focus:text-indigo-600 w-6 h-6 md:w-8 md:h-8">*/}
        {/*                      <img className="icon icon-tabler icon-tabler-messages"*/}
        {/*                           src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg10.svg"*/}
        {/*                           alt="chat"/>*/}
        {/*                    </div>*/}
        {/*                  </li>*/}
        {/*                  <li className="cursor-pointer text-gray-800 pt-5 pb-3 pl-3">*/}
        {/*                    <div tabIndex="0"*/}
        {/*                         className="focus:outline-none focus:text-indigo-600 w-6 h-6 md:w-8 md:h-8">*/}
        {/*                      <img className="icon icon-tabler icon-tabler-bell"*/}
        {/*                           src="https://tuk-cdn.s3.amazonaws.com/can-uploader/left-aligned-with-icons-svg11.svg"*/}
        {/*                           alt="bell"/>*/}
        {/*                    </div>*/}
        {/*                  </li>*/}
        {/*                </ul>*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</nav>*/}
      </div>

  )
}
export default Header;