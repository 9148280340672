import StoreManagementComponent from "../components/StoreManagementComponent";
import DeviceManagementComponent from "../components/DeviceManagementComponent";
import {useEffect, useState} from "react";
import StoreTotalReportCard from "../components/StoreTotalReportCard";
import StoreListTable from "../components/table/StoreListTable";
import fetcher from "../utils/fetcher";
import {GET_STORE_LIST_API, GET_TOTAL_COUNT_REPORT_API} from "../constants/api_constants";
import {accountInfo, TODAY} from "../utils/constants";

const StoreManagementPage = () => {
  const [managementAreaState, setManagementAreaState] = useState(''); // 지점관리, 기기관리 구분
  // 지점리스트 검색 기간
  const [searchStartDate, setSearchStartDate] = useState('2024-01-01');
  const [searchEndDate, setSearchEndDate] = useState(TODAY.split('T')[0]);

  const [selectStore, setSelectStore] = useState({}); // 선택한 지점
  const [storeList, setStoreList] = useState([]); // 지점 리스트

  const [totalCount, setTotalStoreCount] = useState([
    {name: '프로토 지점 수', value: 0},
    {name: '출력 기기 수', value: 0},
    {name: '오늘 출력 수', value: 0},
    {name: '어제 출력 수', value: 0},
  ]);


  useEffect(() => {
    getStoreList();
    getTotalCountReport();
  }, []);

  const getStoreList = () => {
    // 지점 리스트 받아오기
    fetcher.get(GET_STORE_LIST_API + `?loginCode=${accountInfo.accountLoginCode()}&startDate=${searchStartDate}&endDate=${searchEndDate}&page=0&count=1000`)
        .then(res => {
          const {responseIsSuccess, responseResultDescription, storeList} = res.data;
          if (responseIsSuccess) {
            setStoreList(storeList);
          } else {
            console.log(responseResultDescription);
            setStoreList(storeList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }

  const getTotalCountReport = () => {
    // 총지점 수 + 출력 기기수 + 출력 수 등
    fetcher.get(GET_TOTAL_COUNT_REPORT_API + `?loginCode=${accountInfo.accountLoginCode()}`)
        .then(res => {
          const {responseIsSuccess, responseResultDescription} = res.data;
          if (responseIsSuccess) {
            setTotalStoreCount([
              {name: '매장 수', value: res.data.totalStoreCountNumber},
              {name: '출력 기기 수', value: res.data.totalMachineCountNumber},
              {name: '오늘 출력 수', value: res.data.totalTodayPrintCountNumber},
              {name: '어제 출력 수', value: res.data.totalYesterdayPrintCountNumber},
            ]);
          } else {
            console.log(responseResultDescription);
            setStoreList(storeList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }


  return (
      <div className="flex flex-row w-full h-full px-10 pt-5 pb-10">
        {/* <-- 왼쪽 지점 리스트 띄우는 영역 --> */}
        <div className="w-1/2 h-full flex flex-col pr-10">
          <h2 className="text-2xl font-semibold leading-tight ml-5">지점 리스트</h2>

          <div className="flex flex-row justify-between items-start mt-5 px-4">
            <div className="flex flex-row items-center">
              {/* 검색 기간 설정 */}
              <input tabIndex={0} role="input" type="date"
                     value={searchStartDate.split('T')[0]}
                     onChange={(e) => setSearchStartDate(e.target.value)}
                     className="text-base leading-none text-gray-900 px-3 py-2 focus:oultine-none focus:border-indigo-700 bg-gray-50 border rounded border-gray-300"
              />
              <p className="text-2xl px-2"> ~ </p>
              <input tabIndex={0} role="input" type="date"
                     value={searchEndDate.split('T')[0]}
                     onChange={(e) => setSearchEndDate(e.target.value)}
                     className="text-base leading-none text-gray-900 px-3 py-2 focus:oultine-none focus:border-indigo-700 bg-gray-50 border rounded border-gray-300"
              />
              <button className="button-style px-3 py-2 ml-5 text-sm" onClick={getStoreList}>
                검색
              </button>
            </div>

            <button className="button-style py-2.5 whitespace-nowrap"
                    onClick={() => setManagementAreaState("addStore")}>
              지점 등록
            </button>
          </div>

          <StoreListTable setManagementAreaState={setManagementAreaState} setSelectStore={setSelectStore}
                          storeList={storeList}/>
        </div>


        {/* <-- 오른쪽 지점 관리 / 기기 관리 띄우는 영역 --> */}
        <div className="w-1/2 h-full flex flex-col">
          {/* <- 현재 현황 데이터 띄우기 -> */}
          <StoreTotalReportCard totalCount={totalCount}/>

          {/* <- 버튼 선택에 따라 화면 띄우기 -> */}
          {(managementAreaState === "storeManagement" || managementAreaState === "addStore") &&
              <StoreManagementComponent managementAreaState={managementAreaState} selectStore={selectStore}
                                        getStoreList={getStoreList} getTotalCountReport={getTotalCountReport}/>
          }
          {managementAreaState === "deviceManagement" &&
              <DeviceManagementComponent selectStore={selectStore} managementAreaState={managementAreaState}
                                         getStoreList={getStoreList} getTotalCountReport={getTotalCountReport}/>}
        </div>

      </div>
  );
}
export default StoreManagementPage;
