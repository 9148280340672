import {
  HAPPY_PICK_LIST_PAGE, HAPPY_PICK_MANAGEMENT_PAGE,
  STORE_MANAGEMENT_PAGE
} from "./page_constants";
import {
  RiArrowRightSFill,
  RiEmotionHappyFill, RiEmotionHappyLine, RiHome2Line
} from "react-icons/ri";

export const HEADER_MENU = [
    // <-- 이중메뉴 사용할 때 -->
  // {
  //   menuTitle: "메인메뉴", icon: RiEmotionHappyLine,
  //   menuList: [
  //     {name: "서브메뉴1", link: HAPPY_PICK_MANAGEMENT_PAGE, icon: RiArrowRightSFill, current: false},
  //     {name: "서브메뉴2", link: HAPPY_PICK_LIST_PAGE, icon: RiArrowRightSFill, current: false},
  //   ]
  // },


  // {name: "행복픽-관리", link: HAPPY_PICK_MANAGEMENT_PAGE, icon: RiEmotionHappyLine},
  // {name: "행복픽-진행중", link: HAPPY_PICK_LIST_PAGE, icon: RiEmotionHappyFill},

  // {name: "커스텀픽", link: CUSTOM_PICK_MANAGEMENT_PAGE, icon: RiPuzzleLine},
  {name: "지점관리", link: STORE_MANAGEMENT_PAGE, icon: RiHome2Line},
]

export const roundDefaultList = [
  {id: 1, name: "현재 회차", data: {code: 'current'}},
]
export const roundInfo = {
  list: roundDefaultList
};