import {useState} from "react";
import {roundDefaultList, roundInfo} from "../constants/component_constants";
import ListSelectBoxWithDefaultData from "../components/ListSelectBoxWithDefaultData";

const HappyPickManagementPage = () => {
  const [selectedRound, setSelectedRound] = useState(roundDefaultList[0]); // 회차
  const [minimumRate, setMinimumRate] = useState(1.5);
  const [maximumRate, setMaximumRate] = useState(2.5);

  return (
      <div className="flex flex-col h-full items-center px-[10rem]">
        {/* <-- 행복픽 검색 조건 영역 --> */}
        <div className="w-full h-[3rem] mt-5 flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <span className="text-lg font-semibold text-gray-800 mr-5">회차</span>
            <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                          onChange={setSelectedRound}/>

            <span className="text-lg font-semibold text-gray-800 mx-5">배당기준</span>
            <input role="input" type="number" step="0.1"
                   value={minimumRate}
                   onChange={(e) => setMinimumRate(parseFloat(e.target.value) || 0)}
                   className="text-base leading-none text-gray-900 p-2 focus:oultine-none focus:border-violet-700 bg-white border rounded border-gray-300"
            />
            
            <span className="text-xl font-semibold text-gray-800 mx-5">~</span>
            <input role="input" type="number" step="0.1"
                   value={maximumRate}
                   onChange={(e) => setMaximumRate(parseFloat(e.target.value) || 0)}
                   className="text-base leading-none text-gray-900 p-2 focus:oultine-none focus:border-violet-700 bg-white border rounded border-gray-300"
            />
            <button className="button-style ml-5"> 경기 검색</button>
          </div>

          <button className="button-style"> 행복픽 등록</button>
        </div>

        {/* <-- 경기 리스트 영역 --> */}
        <div className="w-full h-full mt-5">
          <div className="w-full h-[3rem] bg-purple-500">검색한 경기 테이블 헤더</div>
          <div className="w-full h-[calc(100%-5rem)] bg-purple-300">테이블 바디 -> 전자게시판 커스텀픽 고대로 들고오면 될듯?</div>
        </div>

      </div>
  );
}
export default HappyPickManagementPage;