
const keyList = [];

function generateFileName() {
  const d = new Date().toISOString().replace(/[-T:.Z]/g, '');
  return d.substring(0, 14);
}

class NeverLandUtils {

  constructor() {
    if (!NeverLandUtils.instance) {
      NeverLandUtils.instance = this;
    }
    return NeverLandUtils.instance;
  }

  Tools = class {

  }

  // <-- 공통 함수 -->
  Commons = class {
    // 로컬스토리지 저장
    save(key, jsonData) {
      if (jsonData === null) {
        localStorage.removeItem(key);
        return;
      }
      if (!keyList.includes(key) && key.includes('*')) keyList.push(key);

      const jsonString = JSON.stringify(jsonData);
      localStorage.setItem(key, jsonString);
    }

    has(key) {
      return localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null;
    }

    // 로컬스토리지 불러오기
    load(key) {
      if (!keyList.includes(key) && key.includes('*')) keyList.push(key);

      const jsonData = localStorage.getItem(key);
      if (jsonData == null) {
        return null;
      }
      try {
        // 문자열 형식이면 JSON 바로 반환
        if (jsonData.startsWith("{") || jsonData.startsWith("[")) {
          return JSON.parse(jsonData);
        } else {
          return jsonData;
        }
      } catch (e) {
        console.log(e);
      }
      return null;
    }

    // 로컬스토리지에서 삭제
    remove(key) {
      localStorage.removeItem(key);
    }

    // 로컬스토리지 비우기
    clear() {
      for (let k of keyList) {
        localStorage.removeItem(k);
      }
      keyList.length = 0;
    }

    clearAll() {
      localStorage.clear();
    }
  };
  Security = class {

  };

  Utils = class {
    formatter1 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    });
    formatter2 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
    formatter3 = new Intl.DateTimeFormat('ko-KR', {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    textToNumber(numberText) {
      numberText = numberText.replace(/\D/g, '');
      return Number(numberText);
    }

    toLocaleString(number) {
      // 천단위 이상 금액에 콤마 붙이기
      if (typeof number === 'number') {
        let current = number.toLocaleString();
        if (current !== 'NaN')
          return current;
      }
      let current = number.toString();

      current = current.replace(/\D/g, '');
      return Number(current).toLocaleString();
    }

    getDisplayDateText(datetime, useDetail = false) {
      if (useDetail) {
        return this.formatter2.format(new Date(datetime));
      }
      // 작성일이 오늘이면 시간만, 오늘이 아니면 날짜만 띄우기
      const toDay = new Date().toISOString().substring(0, 10);
      const writeDate = datetime?.substring(0, 10);
      const writeTime = this.formatter1.format(new Date(datetime));
      if (toDay === writeDate) {
        return writeTime;
      } else return writeDate;
    }

    getDisplayTextByDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    }

    getDisplayTimeText(datetime) {
      return this.formatter3.format(new Date(datetime));
    }

    getDisplayTimeText2(datetime) {
      const parts = this.formatter3.formatToParts(datetime);

      const year = parts.find(part => part.type === 'year').value;
      const month = parts.find(part => part.type === 'month').value;
      const day = parts.find(part => part.type === 'day').value;
      const hour = parts.find(part => part.type === 'hour').value;
      const minute = parts.find(part => part.type === 'minute').value;
      const second = parts.find(part => part.type === 'second').value;


      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  }
  commons = new this.Commons();
  security = new this.Security();
  utils = new this.Utils();
  tools = new this.Tools();
}

const neverLandUtils = new NeverLandUtils();
Object.freeze(neverLandUtils);

export default neverLandUtils;