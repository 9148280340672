import {useEffect, useState} from "react";
import fetcher from "../utils/fetcher";
import {ADD_STORE_API, DELETE_STORE_API, EDIT_STORE_INFO_API} from "../constants/api_constants";
import {accountInfo, TODAY} from "../utils/constants";
import {ModalHandler, OpenModalHandle} from "./modal/ModalHandler";

const MONEY_BUTTON_DATA = [
  {name: "1백",}, {name: "1천",}, {name: "5천",}, {name: "1만",},
  {name: "2만",}, {name: "3만",}, {name: "5만",}, {name: "10만",},
]

const StoreManagementComponent = ({managementAreaState, selectStore, getStoreList, getTotalCountReport}) => {
  const [managementState, setManagementState] = useState(''); // 지점 관리 || 지점 등록
  const [storeName, setStoreName] = useState(''); // 지점명
  const [registrationDate, setRegistrationDate] = useState(''); // 등록일
  const [businessNumber, setBusinessNumber] = useState(''); // 사업자번호
  const [phoneNumber, setPhoneNumber] = useState(''); // 연락처
  const [monthlyCost, setMonthlyCost] = useState(''); // 월비용
  const [storeComment, setStoreComment] = useState(''); // 메모
  const [viewMoneyButtonList, setViewMoneyButtonList] = useState([true, true, true, true, true, true, true, true]); // 금액버튼

  const OpenModal = OpenModalHandle.create();

  useEffect(() => {
    if (managementAreaState === 'storeManagement') {
      setManagementState('지점 관리');
    } else if (managementAreaState === 'addStore') {
      setManagementState('지점 등록');
      clearInput();
    }
    // 지점 리스트에서 관리 선택했을 때 인풋 초기값 설정
    if (managementAreaState === 'storeManagement' && selectStore) {
      setStoreName(selectStore.storeName);
      setRegistrationDate(selectStore.storeRegistrationDateTime ? selectStore.storeRegistrationDateTime : TODAY);
      setBusinessNumber(selectStore.storeBusinessNumber);
      setPhoneNumber(selectStore.storePhoneNumber);
      setMonthlyCost(selectStore.storeMonthlyCost);
      setStoreComment(selectStore.storeComment);
      setViewMoneyButtonList(selectStore.storeIsButtonActiveStatusList);
    }
  }, [selectStore, managementAreaState]);

  const clearInput = () => {
    setStoreName('');
    setRegistrationDate(TODAY);
    setBusinessNumber('');
    setPhoneNumber('');
    setMonthlyCost('');
    setStoreComment('');
    setViewMoneyButtonList([true, true, true, true, true, true, true, true]);
  }
  const setClear = () => {
    clearInput();
    getStoreList();
    getTotalCountReport();
  }

  const handleCheckboxChange = (index) => {
    let updatedList = viewMoneyButtonList.map((item, i) =>
        i === index ? !item : item
    );
    setViewMoneyButtonList(updatedList);
  };

  const registerStoreInfo = () => {
    // 지점 등록하기
    if (!storeName) {
      OpenModal.openWarningModal("등록 실패", "지점명 입력은 필수입니다.");
      return;
    }
    fetcher.post(ADD_STORE_API, {
      loginCode: accountInfo.accountLoginCode(),
      storeName: storeName,
      storeRegistrationDateTime: new Date(registrationDate.toString()),
      storeBusinessNumber: businessNumber,
      storePhoneNumber: phoneNumber,
      storeMonthlyCost: monthlyCost,
      storeComment: storeComment,
      storeIsButtonActiveStatusList: viewMoneyButtonList,
    }).then(res => {
      const {responseIsSuccess, responseResultDescription} = res.data;
      if (responseIsSuccess) {
        OpenModal.openConfirmModal("지점 등록", responseResultDescription);
        setClear();
        getStoreList();
      } else {
        console.log(responseResultDescription);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const deleteStore = () => {
    // 지점 삭제하기
    fetcher.delete(DELETE_STORE_API + `?loginCode=${accountInfo.accountLoginCode()}&storeCodeName=${selectStore.storeCodeName}`)
        .then(res => {
          const {responseIsSuccess, responseResultDescription} = res.data;
          if (responseIsSuccess) {
            OpenModal.openConfirmModal("지점 삭제 성공", responseResultDescription);
            setClear();
          } else {
            OpenModal.openWarningModal("지점 삭제 실패", responseResultDescription);
            console.log(responseResultDescription);
          }
        }).catch((err) => {
      console.log(err);
    });
  }

  const editStoreInfo = () => {
    // 지점 정보 수정
    fetcher.put(EDIT_STORE_INFO_API, {
      loginCode: accountInfo.accountLoginCode(),
      storeCodeName: selectStore.storeCodeName,
      storeName: storeName,
      storeRegistrationDateTime: new Date(registrationDate.toString()),
      storeBusinessNumber: businessNumber,
      storePhoneNumber: phoneNumber,
      storeMonthlyCost: monthlyCost,
      storeComment: storeComment,
      storeIsButtonActiveStatusList: viewMoneyButtonList,
    }).then(res => {
      const {responseIsSuccess, responseResultDescription} = res.data;
      if (responseIsSuccess) {
        OpenModal.openConfirmModal("지점 정보 수정", responseResultDescription);
        getStoreList();
        getTotalCountReport();
      } else {
        console.log(responseResultDescription);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
      <div className="w-full h-full mt-5 bg-white shadow-lg rounded-lg py-12 px-28">
        <ModalHandler initModalHandle={OpenModal}/>
        <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">
          {managementState}
        </p>

        <div className="md:flex items-center mt-14">
          <div className="md:w-72 flex flex-col">
            <label className="text-base font-semibold leading-none text-gray-800">지점명</label>
            <input tabIndex={0} role="input" type="text"
                   value={storeName}
                   onChange={(e) => setStoreName(e.target.value)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>

          <div className="md:w-72 flex flex-col md:ml-12 md:mt-0 mt-2">
            <label className="text-base font-semibold leading-none text-gray-800">등록일</label>
            <input tabIndex={0} role="input" type="date"
                   value={registrationDate.split('T')[0]}
                   onChange={(e) => setRegistrationDate(e.target.value)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>
        </div>

        <div className="md:flex items-center mt-8">
          <div className="md:w-72 flex flex-col">
            <label className="text-base font-semibold leading-none text-gray-800">사업자번호</label>
            <input tabIndex={0} role="input" type="text"
                   value={businessNumber}
                   onChange={(e) => setBusinessNumber(e.target.value)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>

          <div className="md:w-72 flex flex-col md:ml-12 md:mt-0 mt-4">
            <label className="text-base font-semibold leading-none text-gray-800">전화번호</label>
            <input tabIndex={0} role="input" type="tel"
                   value={phoneNumber}
                   onChange={(e) => setPhoneNumber(e.target.value)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>
        </div>

        <div className="md:flex items-center mt-8">
          <div className="md:w-72 flex flex-col">
            <label className="text-base font-semibold leading-none text-gray-800">월비용</label>
            <input tabIndex={0} role="input" type="number"
                   value={monthlyCost}
                   onChange={(e) => setMonthlyCost(parseInt(e.target.value) || 0)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>

          <div className="md:w-72 flex flex-col md:ml-12 md:mt-0 mt-4">
            <label className="text-base font-semibold leading-none text-gray-800">메모</label>
            <input tabIndex={0} type="text"
                   value={storeComment}
                   onChange={(e) => setStoreComment(e.target.value)}
                   className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-50 border rounded border-gray-300"
            />
          </div>
        </div>

        {/* <-- 금액버튼 체크박스 --> */}
        <div className="flex flex-col mt-7">
          <label className="text-base font-semibold leading-none text-gray-800">금액버튼 표시 여부</label>
          <div className="flex flex-row flex-wrap mt-4">
            {MONEY_BUTTON_DATA.map((button, index) => {
              return (
                  <div className="relative flex items-start px-3 py-0.5 mr-2.5 border border-gray-400 rounded-lg"
                       key={button.name}
                  >
                    <div className="flex h-6 items-center">
                      <input
                          id={`setMoneyButtonVisible-${index}`}
                          name="moneyButton"
                          type="checkbox"
                          checked={viewMoneyButtonList[index]} // true = 체크
                          onChange={() => handleCheckboxChange(index)}
                          aria-describedby="comments-description"
                          className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-2 text-sm leading-6">
                      <label htmlFor="comments" className="font-medium text-gray-900">
                        {button.name}
                      </label>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>

        {/* <-- 등록, 수정, 삭제 버튼 --> */}
        <div
            className={`${managementState === '지점 관리' ? "justify-between" : "justify-center"} flex items-center w-full mt-10`}>
          {managementAreaState === "storeManagement" &&
              <button className="button-style mr-10 bg-rose-700 hover:bg-rose-900"
                      onClick={() => OpenModal.openSelectModal("지점 삭제", `[${selectStore.storeName} 지점]을 삭제하시겠습니까?`,
                          {items: [{'text': '삭제', params: '지점 삭제', 'onClick': () => deleteStore()}]})}
              >
                지점 삭제
              </button>
          }

          <button className="button-style mr-20"
                  onClick={() => {
                    if (managementState === '지점 등록') {
                      registerStoreInfo();
                    } else if (managementState === '지점 관리') {
                      OpenModal.openSelectModal("지점 정보 수정", `[${selectStore.storeName} 지점]의 정보를 변경하시겠습니까?`,
                          {items: [{'text': '수정', params: '기기수정', 'onClick': () => editStoreInfo()}]});
                    }
                  }}
          >
            {managementState === '지점 관리' ? "정보 수정" : "지점 등록"}
          </button>
        </div>

      </div>
  );
}
export default StoreManagementComponent;

