import {RiPrinterLine, RiStore2Line} from "react-icons/ri";

const StoreTotalReportCard = ({totalCount}) => {

  return (
      <dl className="w-full mx-auto grid gap-px bg-gray-900/20 grid-cols-4 shadow-md">
        {totalCount.map((item) => (
            <div
                key={item.name}
                className="flex flex-wrap items-baseline justify-between gap-x-4 bg-white px-4 py-3 sm:px-6 xl:px-8"
            >
              <dt className="text-sm font-medium leading-6 text-gray-500">{item.name}</dt>
              {/*<dd*/}
              {/*    className={classNames(*/}
              {/*        stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',*/}
              {/*        'text-xs font-medium',*/}
              {/*    )}*/}
              {/*>*/}
              {/*  {item.change}*/}
              {/*</dd>*/}
              <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                {item.value}
              </dd>
            </div>
        ))}
      </dl>
      // <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      //   <dl>
      //     <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow-md sm:px-6 sm:pt-6">
      //       <dt>
      //         <div className="absolute rounded-md bg-violet-600 p-3">
      //           <RiStore2Line aria-hidden="true" className="h-6 w-6 text-white"/>
      //         </div>
      //         <p className="ml-16 truncate text-sm font-medium text-gray-500">프로토 지점수</p>
      //       </dt>
      //       <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
      //         <p className="text-2xl font-semibold text-gray-900">{totalCount.storeCount}</p>
      //       </dd>
      //     </div>
      //   </dl>
      //
      //   <dl>
      //     <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow-md sm:px-6 sm:pt-6">
      //       <dt>
      //         <div className="absolute rounded-md bg-violet-600 p-3">
      //           <RiPrinterLine aria-hidden="true" className="h-6 w-6 text-white"/>
      //         </div>
      //         <p className="ml-16 truncate text-sm font-medium text-gray-500">오늘 출력 현황</p>
      //       </dt>
      //       <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
      //         <p className="text-2xl font-semibold text-gray-900">{totalCount.printCountToday} 장</p>
      //       </dd>
      //     </div>
      //   </dl>
      //
      //   <dl>
      //     <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow-md sm:px-6 sm:pt-6">
      //       <dt>
      //         <div className="absolute rounded-md bg-violet-600 p-3">
      //           <RiPrinterLine aria-hidden="true" className="h-6 w-6 text-white"/>
      //         </div>
      //         <p className="ml-16 truncate text-sm font-medium text-gray-500">어제 출력 현황</p>
      //       </dt>
      //       <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
      //         <p className="text-2xl font-semibold text-gray-900">{totalCount.printCountYesterday} 장</p>
      //       </dd>
      //     </div>
      //   </dl>
      // </div>
  )
}
export default StoreTotalReportCard;