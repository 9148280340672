const CustomPickManagementPage = () => {

  return (
      <div className="flex flex-col h-full items-center px-[8rem]">
        <div className="w-full h-[3rem] bg-yellow-200 mt-5">
          버튼 영역 (조합생성, 이어서인쇄)
        </div>

        <div className="w-full h-full bg-yellow-300 mt-5">
          <div className="w-full h-[3rem] bg-amber-500">테이블 헤더</div>
          경기정보
        </div>

      </div>
  );
}
export default CustomPickManagementPage;