import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {
  RiCloseLine,
  RiEyeFill,
  RiEyeOffFill, RiKeyFill,
  RiLockFill,
  RiUserFill
} from "react-icons/ri";
import sha256 from "crypto-js/sha256";
import fetcher from "../../utils/fetcher";
import {CREATE_ACCOUNT_API} from "../../constants/api_constants";
import {ModalHandler, OpenModalHandle} from "./ModalHandler";


const SignUpModal = ({setShowSignUpModal}) => {
  const [open, setOpen] = useState(true);
  // 가입 데이터
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [authCode, setAuthCode] = useState(''); // 인증 코드
  // 유효성 검사
  const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  // 비밀번호 보이기
  const [showPasswordFields, setShowPasswordFields] = useState({prePassword: false, passwordConfirm: false});

  const OpenModal = OpenModalHandle.create();


  // <-- 비밀번호 입력 -->
  const onChangePassword = (newPassword, newPasswordConfirm) => {
    if (newPassword !== password) {
      setPassword(newPassword);
    }
    if (newPasswordConfirm !== passwordConfirm) {
      setPasswordConfirm(newPasswordConfirm);
    }
    if (newPassword === newPasswordConfirm) {
      setIsPasswordConfirm(true);
    } else {
      setIsPasswordConfirm(false);
    }
  }
  // 비밀번호 보이기 처리
  const toggleVisiblePassword = (fieldName) => {
    setShowPasswordFields(prevState => ({
      ...prevState, [fieldName]: !prevState[fieldName]
    }));
  }

  // 암호화 처리
  const encryptInputValue = (value) => {
    return String(sha256(value));
  }

  const createAccount = () => {
    // 계정 생성
    fetcher.post(CREATE_ACCOUNT_API, {
      accountName: name,
      accountLoginId: id.toLowerCase(),
      accountPassword: encryptInputValue(password),
      accountLevelEnum: authCode, // 가입 승인 코드 ( 테스트용 : sNGw0K1zJ4y}nYdpJJb@ )
      accountTypeEnum: '전자게시판', // 계정 타입 (추후 카카오, 네이버 로그인 추가 고려)
    })
        .then(res => {
          const {responseIsSuccess, responseResultDescription} = res.data;
          if (responseIsSuccess) {
            OpenModal.openConfirmModal("계정생성 성공", responseResultDescription);
            setIsCreateAccount(true);
          } else {
            OpenModal.openWarningModal('회원 가입 실패', responseResultDescription);
          }
        })
        .catch((err) => {
          console.log(err);
          OpenModal.openWarningModal('회원가입 실패', '회원가입에 실패하였습니다.');
        });
  }

  const onClickCreateAccountButton = () => {
    if (!name || !id || !password || !passwordConfirm || !authCode) {
      OpenModal.openWarningModal('정보 입력', '빈칸을 모두 입력해주세요.');
      return;
    }
    if (!isPasswordConfirm)
        // 비밀번호 일치 여부 -> 인풋창 밑에 경고 텍스트 뜨기 때문에 별도로 모달 안띄움
      return;
    createAccount();
  }

  const closeModal = () => {
    setOpen(false);
    setShowSignUpModal(false);
  }


  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <ModalHandler initModalHandle={OpenModal}/>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative z-10 transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-80 sm:max-w-lg sm:p-6">

                  <button onClick={closeModal} className="ml-64">
                    <RiCloseLine className="w-5 h-5"/>
                  </button>

                  <div>
                    <div className="text-center sm:my-2">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">관리자 계정생성</h3>
                    </div>
                  </div>

                  <div>
                    {/*  <-- 회원가입 인풋 --> */}
                    <div className="relative mt-3 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiUserFill className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                      </div>
                      <input
                          onChange={(e) => setName(e.target.value.trim())}
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="이름"
                          disabled={isCreateAccount}
                      />
                    </div>

                    <div className="relative mt-5 rounded-md shadow-sm flex flex-row">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiUserFill className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                      </div>
                      <input
                          onChange={(e) => setId(e.target.value.trim())}
                          className="block w-full mr-2 rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="아이디"
                          disabled={isCreateAccount}
                      />
                    </div>

                    <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiLockFill className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                      </div>
                      <input
                          onChange={e => onChangePassword(e.target.value.trim(), passwordConfirm)} //e => setPassword(e.target.value)
                          type={showPasswordFields.password ? "text" : "password"}
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="비밀번호"
                          disabled={isCreateAccount}
                      />
                      <div className="absolute px-3 text-gray-400 right-0">
                        {showPasswordFields.password ?
                            <RiEyeFill className="h-5 w-auto"
                                       onClick={() => toggleVisiblePassword('password')}/> :
                            <RiEyeOffFill className="h-5 w-auto"
                                          onClick={() => toggleVisiblePassword('password')}/>
                        }
                      </div>
                    </div>

                    <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiLockFill className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                      </div>
                      <input
                          onChange={e => onChangePassword(password, e.target.value.trim())}
                          type={showPasswordFields.passwordConfirm ? "text" : "password"}
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="비밀번호 확인"
                          disabled={isCreateAccount}
                      />
                      <div className="absolute px-3 text-gray-400 right-0">
                        {showPasswordFields.passwordConfirm ?
                            <RiEyeFill className="h-5 w-auto"
                                       onClick={() => toggleVisiblePassword('passwordConfirm')}/> :
                            <RiEyeOffFill className="h-5 w-auto"
                                          onClick={() => toggleVisiblePassword('passwordConfirm')}/>
                        }
                      </div>
                    </div>
                    {password && passwordConfirm && (
                        <p className={`mt-2 text-sm ${isPasswordConfirm ? "text-green-600" : "text-red-600"}`}>
                          {isPasswordConfirm ? "비밀번호가 일치합니다." : "비밀번호가 일치하지 않습니다."}
                        </p>
                    )}
                    <div className="relative mt-3 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <RiKeyFill className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                      </div>
                      <input
                          onChange={(e) => setAuthCode(e.target.value.trim())}
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="인증코드"
                          disabled={isCreateAccount}
                      />
                    </div>
                  </div>

                  <div className="mt-5 flex flex-row justify-center sm:mt-6 sm:grid-cols-2 sm:gap-3">
                    <button
                        onClick={onClickCreateAccountButton}
                        type="button"
                        disabled={isCreateAccount}
                        className={`${isCreateAccount ? "cursor-not-allowed bg-gray-500 hover:bg-gray-500" : "bg-gray-900 hover:bg-gray-700"} w-24 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm`}
                    >
                      생성하기
                    </button>
                    <button
                        onClick={closeModal}
                        type="button"
                        className="w-24 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      닫기
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default SignUpModal;
