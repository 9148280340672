import HappyPickGameListTable from "../components/table/HappyPickGameListTable";
import {RiRefreshLine} from "react-icons/ri";
import HappyPickBattingListTable from "../components/table/HappyPickBattingListTable";

const HappyPickOngoingListPage = () => {


  return (
      <div className="flex flex-row h-full px-[7rem] py-8">
        <div className="w-1/2 h-full flex flex-col mr-8">
          <div className="w-full h-[3rem] flex flex-row justify-start items-start">
            <p className="text-2xl text-left font-bold"> 행복픽 경기 목록 </p>
            <button className="button-style bg-blue-700 hover:bg-blue-600 items-center flex flex-row px-5 py-2 ml-5">
              <RiRefreshLine className="w-5 h-auto font-bold mr-2"/>
              결과 갱신
            </button>
          </div>

          <div
              className="w-full h-[calc(100%-3rem)] border border-gray-300 shadow-md rounded-lg p-5 items-center bg-white">
            <HappyPickGameListTable/>
          </div>
        </div>

        <div className="w-1/2 h-full flex flex-col ml-8">
          <p className="text-2xl h-[3rem] leading-7 text-left mr-3 font-bold"> 배팅목록 </p>
          <div className="w-full h-[calc(100%-3rem)] border border-gray-300 shadow-md rounded-lg p-5 items-center bg-white">
            <HappyPickBattingListTable/>
          </div>
        </div>
      </div>
  );
}
export default HappyPickOngoingListPage;