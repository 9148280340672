import {_deviceList, _happyPickBattingList, _happyPickGameList, _storeList} from "../../_dummyData";
import {RiListCheck, RiRefreshLine} from "react-icons/ri";

const HappyPickBattingListTable = () => {
  return (
      <div className="w-full h-full overflow-x-auto overflow-y-auto border border-gray-300 bg-white">
        <table className="min-w-full">
          <thead className="bg-blue-600 text-white sticky top-0">
          <tr className="text-center">
            <th className="p-3">경기번호</th>
            <th className="p-3">홈팀</th>
            <th className="p-3">스코어</th>
            <th className="p-3">원정팀</th>
            <th className="p-1">배팅</th>
            <th className="p-3">배당</th>
          </tr>
          </thead>

          <tbody>
          {_happyPickBattingList.map((data, dataIndex) => (
              <>
                {data.gameList.map((game, gameIndex) => (
                    <tr key={`${dataIndex}-${gameIndex}`}
                        className="border-b border-opacity-60 border-gray-300 hover:bg-gray-100 text-center">
                      <td className="p-3">
                        <p>{game.gameNumber}</p>
                      </td>
                      <td className="p-3">
                        <p>{game.homeTeam}</p>
                      </td>
                      <td className="p-3 tracking-widest">
                        <p>{game.score}</p>
                      </td>
                      <td className="p-3 tracking-widest">
                        <p>{game.awayTeam}</p>
                      </td>
                      <td className="p-3">
                        <p className="bg-blue-200 rounded-full">{game.batting}</p>
                      </td>
                      <td className="p-3">
                        <p>{game.rate}</p>
                      </td>
                    </tr>
                ))}

                {/* 구분선 */}
                <tr key={`divider-${dataIndex}`}>
                  <td colSpan="6" className="bg-blue-900 text-center h-3"/>
                </tr>
              </>
          ))}
          </tbody>
        </table>
      </div>
  )
}
export default HappyPickBattingListTable;