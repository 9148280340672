import DeviceListTable from "./table/DeviceListTable";
import fetcher from "../utils/fetcher";
import {
  ADD_DEVICE_API,
  DELETE_DEVICE_API,
  DELETE_STORE_API,
  EDIT_DEVICE_INFO_API,
  GET_DEVICE_LIST_API
} from "../constants/api_constants";
import {accountInfo, TODAY} from "../utils/constants";
import {useEffect, useState} from "react";
import {ModalHandler, OpenModalHandle} from "./modal/ModalHandler";

const DeviceManagementComponent = ({selectStore, getStoreList, getTotalCountReport}) => {
  const [deviceList, setDeviceList] = useState([]); // 기기 리스트
  const [deviceNumber, setDeviceNumber] = useState(''); // 기기 번호
  const [selectDevice, setSelectDevice] = useState({}); // 선택한 기기
  const [registrationDate, setRegistrationDate] = useState(''); // 등록일
  const [deviceComment, setDeviceComment] = useState(''); // 메모

  const OpenModal = OpenModalHandle.create();

  useEffect(() => {
    getStoreDeviceList();
    clearInput();
    setSelectDevice({});
  }, [selectStore]);

  useEffect(() => {
    // 기기 리스트에서 관리 선택했을 때 인풋 초기값 설정
    if (selectDevice) {
      setDeviceNumber(selectDevice.machineNumber);
      setRegistrationDate(selectDevice.machineRegistrationDateTime ? selectDevice.machineRegistrationDateTime : TODAY);
      setDeviceComment(selectDevice.machineComment);
    }
  }, [selectDevice]);

  const clearInput = () => {
    setDeviceNumber('');
    setRegistrationDate(TODAY);
    setDeviceComment('');
  }

  const setClear = () => {
    clearInput();
    getStoreDeviceList();
    getStoreList();
    getTotalCountReport();
  }

  const registerDeviceInfo = () => {
    if (!deviceNumber) {
      OpenModal.openWarningModal("등록 실패", "기기번호 입력은 필수입니다.");
      return;
    }
    // 기기 등록하기
    fetcher.post(ADD_DEVICE_API, {
      loginCode: accountInfo.accountLoginCode(),
      storeCodeName: selectStore.storeCodeName,
      machineNumber: deviceNumber,
      machineRegistrationDateTime: new Date(registrationDate.toString()),
      machineComment: deviceComment,
    }).then(res => {
      const {responseIsSuccess, responseResultDescription} = res.data;
      if (responseIsSuccess) {
        OpenModal.openConfirmModal("기기 등록 성공", responseResultDescription);
        setClear();
      } else {
        OpenModal.openWarningModal("기기 등록 실패", responseResultDescription);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const getStoreDeviceList = () => {
    // 지점별 기기 리스트 불러오기
    fetcher.get(GET_DEVICE_LIST_API + `?loginCode=${accountInfo.accountLoginCode()}&storeCodeName=${selectStore.storeCodeName}&page=0&count=20`)
        .then(res => {
          const {responseIsSuccess, responseResultDescription, machineList} = res.data;
          if (responseIsSuccess) {
            setDeviceList(machineList);
          } else {
            console.log(responseResultDescription);
            setDeviceList([]);
          }
        }).catch((err) => {
      console.log(err);
    });
  }

  const deleteDevice = () => {
    // 기기 삭제하기
    fetcher.delete(DELETE_DEVICE_API + `?loginCode=${accountInfo.accountLoginCode()}&machineCodeName=${selectDevice.machineCodeName}`)
        .then(res => {
          const {responseIsSuccess, responseResultDescription} = res.data;
          if (responseIsSuccess) {
            OpenModal.openConfirmModal("기기 삭제 성공", responseResultDescription);
            setClear();
            setSelectDevice({});
          } else {
            OpenModal.openWarningModal("기기 삭제 실패", responseResultDescription);
            console.log(responseResultDescription);
          }
        }).catch((err) => {
      console.log(err);
    });
  }

  const editDeviceInfo = () => {
    // 기기 정보 수정
    fetcher.put(EDIT_DEVICE_INFO_API, {
      loginCode: accountInfo.accountLoginCode(),
      storeCodeName: selectStore.storeCodeName,
      machineCodeName: selectDevice.machineCodeName,
      machineNumber: deviceNumber,
      machineRegistrationDateTime: new Date(registrationDate.toString()),
      machineComment: deviceComment,
    }).then(res => {
      const {responseIsSuccess, responseResultDescription} = res.data;
      if (responseIsSuccess) {
        OpenModal.openConfirmModal("정보 수정 성공", responseResultDescription);
        setClear();
      } else {
        OpenModal.openWarningModal("정보 수정 실패", responseResultDescription);
      }
    }).catch((err) => {
      console.log(err);
    });
  }


  return (
      <div className="w-full h-full mt-5 bg-white shadow-lg rounded-lg p-5 text-gray-700">
        <ModalHandler initModalHandle={OpenModal}/>
        <div className="w-full h-[17.5rem]">
          {/* <-- 지점 정보 --> */}
          <div className="w-full h-28 font-bold border-b border-gray-300">
            <div className="flex flex-row justify-between mb-1 items-center">
              <div className="flex flex-row items-end">
                <p className="text-3xl leading-7 text-left mr-3 bg-violet-200 rounded-full px-5 py-2"> {selectStore.storeName} </p>
                {selectStore.storePhoneNumber && <p> ( {selectStore.storePhoneNumber} ) </p>}
              </div>

              <div className="flex flex-col text-gray-500 pr-5 whitespace-nowrap text-left">
                <p className="my-2"> 등록일 : {selectStore.storeRegistrationDateTime?.split('T')[0]}</p>
                <p> 사업자번호 : {selectStore.storeBusinessNumber} </p>
              </div>
            </div>

            {selectStore.storeComment &&
                <p className="text-gray-500 bg-yellow-100 inline-block px-2"> 지점 메모 : {selectStore.storeComment}  </p>
            }
          </div>

          {/* <-- 기기정보 입력/수정 --> */}
          <div className="w-full mt-5 flex flex-col justify-center">
            <div className="flex flex-row justify-between items-center">
              <p className="text-2xl leading-7 text-left mr-3 font-bold"> 기기 관리 </p>
              <div className="flex flex-row">
                <button className="button-style py-2" onClick={registerDeviceInfo}>
                  기기 등록
                </button>
                {Object.keys(selectDevice).length > 0 &&
                    <div>
                      <button className="button-style py-2 bg-rose-700 hover:bg-rose-900 mx-4"
                              onClick={() => OpenModal.openSelectModal("기기 삭제", `[${selectStore.storeName}]지점 ${deviceNumber}번 기기 삭제`,
                                  {items: [{'text': '삭제', 'onClick': () => deleteDevice()}]})}
                      >
                        기기 삭제
                      </button>

                      <button className="button-style py-2 bg-blue-700 hover:bg-blue-900"
                              onClick={() => OpenModal.openSelectModal("기기 정보 수정", `[${selectStore.storeName}]지점 ${deviceNumber}번 기기 정보 수정`,
                                  {items: [{'text': '수정', 'onClick': () => editDeviceInfo()}]})}
                      >
                        기기 수정
                      </button>
                    </div>
                }
              </div>
            </div>

            <div className="flex flex-row items-center justify-between mt-5">
              <div className="flex flex-col">
                <label className="font-semibold mr-4 mb-1"> 기기번호 </label>
                <input role="input" type="number"
                       value={deviceNumber}
                       onChange={(e) => setDeviceNumber(parseInt(e.target.value) || 0)}
                       className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 bg-gray-50 border rounded border-gray-300"
                />
              </div>

              <div className="flex flex-col">
                <label className="font-semibold mr-4 mb-1">등록일</label>
                <input role="input" type="date"
                       value={registrationDate.split('T')[0]}
                       onChange={(e) => setRegistrationDate(e.target.value)}
                       className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 bg-gray-50 border rounded border-gray-300"
                />
              </div>

              <div className="flex flex-col">
                <label className="font-semibold mr-4 mb-1"> 메모 </label>
                <input role="input" type="text"
                       value={deviceComment}
                       onChange={(e) => setDeviceComment(e.target.value)}
                       className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 bg-gray-50 border rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <-- 기기현황 테이블 --> */}
        <div className="w-full h-[22.5rem]">
          {deviceList.length >= 1 ?
              <DeviceListTable deviceList={deviceList} setSelectDevice={setSelectDevice}/>
              :
              <div className="text-3xl text-center text-gray-400 pt-28">
                등록된 기기가 없습니다.
              </div>
          }
        </div>
      </div>
  );
}
export default DeviceManagementComponent;