import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import sha256 from 'crypto-js/sha256';
import {RiCloseCircleFill, RiEyeFill, RiEyeOffFill, RiLockFill, RiUserFill} from "react-icons/ri";
import {HAPPY_PICK_MANAGEMENT_PAGE, STORE_MANAGEMENT_PAGE} from "../constants/page_constants";
import {LOGIN_API} from "../constants/api_constants";
import fetcher from "../utils/fetcher";
import icon from "../assets/icon.png";
import SignUpModal from "../components/modal/SignUpModal";
import {ModalHandler, OpenModalHandle} from "../components/modal/ModalHandler";
import neverLandUtils from "../utils/NeverLandUtils";
import {LOGIN_CODE} from "../utils/constants";


const LoginPage = () => {
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const OpenModal = OpenModalHandle.create();

  const encryptPassword = (password) => {
    // 비밀번호 암호화
    return String(sha256(password));
  }

  const login = () => {
    // 로그인
    if (!id || !password)
      OpenModal.openWarningModal('입력 확인', '이메일, 비밀번호 모두 입력하세요.');

    fetcher.post(LOGIN_API, {
      accountLoginId: id.toLowerCase(),
      accountPassword: encryptPassword(password),
      accountTypeEnum: '전자게시판' // 계정 타입 (추후 카카오, 네이버 로그인 추가 고려)
    })
        .then(res => {
          const {responseIsSuccess, responseResultDescription, loginCode} = res.data;
          if (responseIsSuccess) {
            neverLandUtils.commons.save(LOGIN_CODE, loginCode);
            navigate(STORE_MANAGEMENT_PAGE);
            // TODO:로그인할때 받는 정보 로컬에 저장
          } else {
            OpenModal.openWarningModal('로그인 실패', responseResultDescription);
          }
        })
        .catch((err) => {
          console.log(err);
          OpenModal.openWarningModal('로그인 실패', '로그인에 실패하였습니다.');
        });
  }


  return (
      <>
        <ModalHandler initModalHandle={OpenModal}/>
        {showSignUpModal && <SignUpModal setShowSignUpModal={setShowSignUpModal}/>}
        <div className="flex min-h-screen flex-1 flex-col justify-center items-center">
          <div className="flex flex-row justify-center items-center mb-7">
            <img src={icon} alt="logo" className="h-20 w-auto mr-1"/>
            <p className="text-3xl font-bold">전자게시판 관리자</p>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="space-y-4">
              <div className="relative rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <RiUserFill className="h-5 w-5 text-gray-400"/>
                </div>
                <input
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    name="id"
                    autoComplete="id"
                    placeholder="아이디"
                    required
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 sm:leading-6"
                />

                <RiCloseCircleFill className="cursor-pointer absolute px-3 text-gray-400 right-0 h-4 w-auto"
                                   onClick={() => setId('')}/>
              </div>

              <div className="relative mt-5 rounded-md shadow-sm flex flex-row items-center">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <RiLockFill className="h-5 w-5 text-gray-400"/>
                </div>
                <input
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') login();
                    }}
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="비밀번호"
                    required
                    className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 sm:leading-6"
                />
                <div className="absolute px-3 text-gray-400 right-0">
                  {showPassword ?
                      <RiEyeFill className="cursor-pointer h-5 w-auto"
                                 onClick={() => setShowPassword(!showPassword)}/> :
                      <RiEyeOffFill className="cursor-pointer h-5 w-auto"
                                    onClick={() => setShowPassword(!showPassword)}/>
                  }
                </div>
              </div>

              <div>
                <button
                    onClick={login}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700"
                >
                  로그인
                </button>
              </div>
            </div>
          </div>

          <button className="ml-2 mt-5 font-semibold leading-6 text-gray-500 hover:text-gray-400 underline"
                  onClick={() => setShowSignUpModal(true)}
          >
            회원가입
          </button>
        </div>
      </>
  )
}
export default LoginPage;