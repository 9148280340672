import axios from "axios";

const {REACT_APP_API_BASE_URL} = process.env;

function _getBaseURL(url) {
  let currentPath = window.location.href;

  if (currentPath.includes("https://") && url.includes("http://")) {
    url = "https://" + new URL(currentPath).hostname + "/api/";
  } else {
  }
  return url;
}

const fetcher = axios.create({
  baseURL: _getBaseURL(REACT_APP_API_BASE_URL)
});

export default fetcher;